<!--
 * @Description: 全部调度任务
 * @Author: ChenXueLin
 * @Date: 2021-10-14 08:59:17
 * @LastEditTime: 2022-08-25 15:45:07
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <i class="e6-icon-history" title="历史记录" @click="toRecord"></i>
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="scheduleTaskId">
              <el-input
                v-model="searchForm.scheduleTaskId"
                placeholder="调度任务ID"
                title="调度任务ID"
                maxlength="10"
                @input="
                  searchForm.scheduleTaskId = searchForm.scheduleTaskId.replace(
                    /[^0-9-]+/,
                    ''
                  )
                "
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--1" prop="schedulestatusList">
              <e6-vr-select
                v-model="searchForm.schedulestatusList"
                :data="scheduleTaskStatusList"
                placeholder="调度任务状态"
                title="调度任务状态"
                clearable
                multiple
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="assignEngineerId">
              <e6-vr-select
                v-model="searchForm.assignEngineerId"
                :data="leaderList"
                placeholder="片区工程组长"
                title="片区工程组长"
                clearable
                :props="{
                  id: 'employeeId',
                  label: 'employeeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="cityName">
              <el-input
                v-model="searchForm.cityName"
                placeholder="所属片区"
                title="所属片区"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--1" prop="createByName">
              <el-input
                v-model="searchForm.createByName"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpName">
              <input-select
                v-model="searchForm.corpName"
                :data="corpList"
                placeholder="客户名称"
                title="客户名称"
                clearable
                virtual
                remote
                :is-title="true"
                @filterChange="handleLoadCorpFilter"
                :props="{
                  id: 'corpName',
                  label: 'corpName'
                }"
              ></input-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="contactId">
              <el-input
                v-model="searchForm.contactName"
                placeholder="第二联系人"
                title="第二联系人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="contactPhone">
              <el-input
                v-model="searchForm.contactPhone"
                placeholder="第二联系电话"
                title="第二联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="insAddress">
              <el-input
                v-model="searchForm.insAddress"
                placeholder="安装地址"
                title="安装地址"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--1" prop="labelType">
              <e6-vr-select
                v-model="searchForm.labelType"
                :data="workTagsList"
                placeholder="标签选择"
                title="标签选择"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskNumber">
              <el-input
                v-model="searchForm.taskNumber"
                placeholder="所属任务单号"
                title="所属任务单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskSecondClass">
              <e6-vr-select
                v-model="searchForm.taskSecondClass"
                :data="taskSecondClassList"
                placeholder="任务单类型"
                title="任务单类型"
                multiple
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="finishTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="完成时间"
                ref="effectiveDatePicker"
                v-model="searchForm.finishTime"
                title="完成时间"
                :picker-options="pickerOptions('searchForm.finishTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="完成时间（始）"
                end-placeholder="完成时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="expectTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="预约上门时间"
                ref="effectiveDatePicker"
                v-model="searchForm.expectTime"
                title="预约上门时间"
                :picker-options="pickerOptions('searchForm.expectTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="预约上门时间（始）"
                end-placeholder="预约上门时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <div class="table-label" v-if="column.fieldName === 'tagValue'">
                <span
                  v-for="(item, index) in row.tagValue"
                  :key="index"
                  class="label-box"
                  >{{ item }}</span
                >
              </div>
              <span
                v-else-if="column.fieldName === 'status'"
                :class="[
                  'status',
                  row.status === 1
                    ? 'finish'
                    : row.status === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                已完成
              </span>

              <span v-else-if="column.fieldName === 'taskNumber'">
                <el-button type="text" @click="handleGoToDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="160"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 查看异常提交原因 -->
    <abnormal-reason
      :reasonVisible="reasonVisible"
      :reasonLabel="reasonLabel"
      :title="dialogTitle"
      :dialogLoading="dialogLoading"
      @handleClose="handleClose"
      :reasonForm="reasonForm"
    ></abnormal-reason>
    <!-- 异常提交输入 -->
    <abnormal-finish-dialog
      :abnormalFinishDialog="abnormalFinishDialog"
      abnormalType="1"
      :scheduleId="scheduleId"
      @handleClose="handleClose"
      @refreshData="queryList"
    ></abnormal-finish-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { exportData } from "@/utils/download";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import {
  getScheduleList,
  findDownList,
  getListLeader,
  urgent,
  taskAbnormal,
  exportScheduleTask
} from "@/api";
import AbnormalReason from "@/components/workOrderManage/abnormalReason.vue";
import AbnormalFinishDialog from "@/components/workOrderManage/abnormalFinishDialog.vue";

export default {
  name: "allSchedulingTasks",
  data() {
    return {
      loading: false,
      searchForm: {
        scheduleTaskId: "", //调度任务ID
        corpName: "", //客户名称
        schedulestatusList: ["1", "2"], //调度任务状态
        assignEngineerId: "", //组长Id
        cityName: "", //所属片区Id
        createByName: "", //创建人
        contactPhone: "", //联系人电话
        contactId: "", //联系人Id
        taskSecondClass: [], //任务二级类型
        insAddress: "", //安装地址
        taskNumber: "", //任务单号
        labelType: "", //标签类型
        expectTime: [], //要求完成时间
        expecttimeStart: "", //期待解决时间开始
        expecttimeend: "", //期待解决时间结束
        createTime: [], //创建时间
        createdTimeStart: "", //期待解决时间开始
        createdTimeEnd: "", //期待解决时间结束
        finishTime: [], //完成时间
        finishTimeStart: "", //期待解决时间开始
        finishTimeEnd: "", //期待解决时间结束
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      columnData: [
        {
          fieldName: "tagValue",
          display: true,
          fieldLabel: "标签",
          width: 250,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "taskNumber",
          display: true,
          fieldLabel: "所属任务单",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        },
        {
          fieldName: "workSecondClassName",
          display: true,
          fieldLabel: "任务单类型",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "scheduleId",
          display: true,
          fieldLabel: "调度任务ID",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskArrangedate",
          display: true,
          fieldLabel: "预约上门时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "schedulestatusName",
          display: true,
          fieldLabel: "调度任务状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "bigAreaName",
          display: true,
          fieldLabel: "所属大区",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "areaName",
          display: true,
          fieldLabel: "所属片区",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "insAddress",
          display: true,
          fieldLabel: "作业地址",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "assignEngineerName",
          display: true,
          fieldLabel: "片区工程组长",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "changeDateNum",
          display: true,
          fieldLabel: "改约次数",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createByName",
          display: true,
          fieldLabel: "调度任务创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "调度任务创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "finishTime",
          display: true,
          fieldLabel: "调度任务完成时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskCreatedTime",
          display: true,
          fieldLabel: "任务单创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpProp",
          display: true,
          fieldLabel: "客户属性",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "project",
          display: true,
          fieldLabel: "所属项目",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installCount",
          display: true,
          fieldLabel: "任务项总数",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactName",
          display: true,
          fieldLabel: "第二联系人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactPhone",
          display: true,
          fieldLabel: "第二联系人电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noAssignCount",
          display: true,
          fieldLabel: "待指派",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitAccept",
          display: true,
          fieldLabel: "待接受",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitWork",
          display: true,
          fieldLabel: "待作业",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workingCount",
          display: true,
          fieldLabel: "作业中",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitVerify",
          display: true,
          fieldLabel: "待审核",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "finishCount",
          display: true,
          fieldLabel: "已完成",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinishCount",
          display: true,
          fieldLabel: "异常完成",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskFinishTime",
          display: true,
          fieldLabel: "任务完结时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      scheduleTaskStatusList: [], //调度任务状态下拉框
      workTagsList: [], //标签下拉框
      taskSecondClassList: [], //任务单类型
      corpList: [], //客户名称
      leaderList: [], //工程组长下拉框
      /*****异常完成*********/
      abnormalFinishDialog: false,
      scheduleId: "", //点击的调度任务ID
      /****查看异常提交原因*****/
      dialogLoading: false,
      reasonVisible: false, //异常提交原因
      dialogTitle: "查看异常完成原因",
      reasonForm: {
        reason: "",
        remark: ""
      },
      reasonLabel: "异常原因"
    };
  },
  components: {
    AbnormalFinishDialog,
    AbnormalReason
  },
  mixins: [listPage, base, listPageReszie],
  watch: {
    // 期望解决时间
    "searchForm.expectTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.expectTime = this.searchForm.expectTime.reverse();
        }
        this.searchForm.expecttimeStart = val[0] || "";
        this.searchForm.expecttimeend = val[1] || "";
      }
    },
    // 创建时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdTimeStart = val[0] || "";
        this.searchForm.createdTimeEnd = val[1] || "";
      }
    },
    // 完成时间
    "searchForm.finishTime": {
      immediate: true,
      handler(val) {
        this.searchForm.finishTimeStart = val[0] || "";
        this.searchForm.finishTimeEnd = val[1] || "";
      }
    }
  },
  computed: {},
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          findDownList(["scheduleTaskStatus", "workTags", "taskSecondClass"]),
          getListLeader({ areaRoleType: 1 })
        ];
        let [taskTypeRes, leaderRes] = await Promise.all(promiseList);
        //调度任务状态下拉框
        this.scheduleTaskStatusList = this.getFreezeResponse(taskTypeRes, {
          path: "data.scheduleTaskStatus"
        });
        //标签
        this.workTagsList = this.getFreezeResponse(taskTypeRes, {
          path: "data.workTags"
        });
        //任务单类型
        this.taskSecondClassList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskSecondClass"
        });
        //获取工程组长
        this.leaderList = this.getFreezeResponse(leaderRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //获取片区大区名字集合
    intersection(a, b) {
      //大区片区信息集合，b片区大区ID集合
      const newList = a.filter(item => b.includes(item.areaId));
      let data = [];
      if (newList.length) {
        data = newList.map(item => item.areaName);
      }
      return data;
    },
    //查询全部调度任务
    async queryList() {
      try {
        this.loading = true;
        if (!this.searchForm.scheduleTaskId) {
          delete this.searchForm.scheduleTaskId;
        }
        let res = await getScheduleList(this.searchForm);
        let tableData = res.data.array;
        tableData.map(item => {
          item.tagValue = item.tagValue ? item.tagValue.split(",") : [];
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //导出工单
    handleExport() {
      exportData(this, exportScheduleTask);
    },
    // 日志
    toRecord() {
      this.routerPush({
        name: "workOrderScheduleTaskRecord",
        params: {
          refresh: true
        }
      });
    },
    // 获取页面操作权限
    getOperateList(row) {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      if (row.schedulestatus != 4 && row.schedulestatus != 3) {
        operates.push({
          label: "异常完成",
          id: 2
        });
        if (row.isUrgent) {
          //已加急过
          operates.push({
            label: "取消加急",
            id: 4
          });
        } else {
          operates.push({
            label: "任务加急",
            id: 4
          });
        }
      }
      if (row.schedulestatus == 4) {
        operates.push({
          label: "查看异常提交原因",
          id: 3
        });
      }

      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "taskListManage/schedulingTaskDetails",
          params: {
            refresh: true,
            scheduleId: row.scheduleId
          }
        });
      }
      if (val.id == 2) {
        //异常完成
        this.abnormalFinishDialog = true;
        this.scheduleId = row.scheduleId;
      }
      if (val.id == 3) {
        //查看异常提交原因
        this.reasonVisible = true;
        this.dialogTitle = "查看异常原因";
        this.reasonLabel = "异常原因";
        this.getTaskAbnormal(row.scheduleId);
      }
      if (val.id == 4) {
        //取消加急、任务加急
        this.handleUrgent(row.scheduleId, row.taskId);
      }
    },
    //调度任务---查看异常提交原因
    async getTaskAbnormal(scheduleId) {
      try {
        this.dialogLoading = true;
        let res = await taskAbnormal({
          scheduleId,
          type: 1
        });
        if (res.code == "OK") {
          this.reasonForm = res.data;
          this.reasonVisible = true;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //调度任务加急、取消加急
    async handleUrgent(patchTaskId, taskId) {
      try {
        this.loading = true;
        let res = await urgent({
          taskId: taskId,
          patchTaskId
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //去任务单详情
    handleGoToDetail(row) {
      this.routerPush({
        name: "taskListManage/detail",
        params: {
          refresh: true,
          taskId: row.taskId
        }
      });
    },
    handleClose(name) {
      this[name] = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.table-label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .label-box {
    padding: 0px 10px;
    border-radius: 20px;
    display: inline-block;
    border: 1px solid #46bfea;
    color: #46bfea;
    margin: 5px;
  }
}
.status {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  background: #2791f7;
  border-radius: 20px;
  border: 1px solid #2791f7;
  color: #fff;
}
.finish {
  background: #e19b39;
  border: 1px solid #e19b39;
}
.ongoing {
  background: #69c43b;
  border: 1px solid #69c43b;
}
</style>
